import React,{Component} from 'react'
import axios from 'axios'
import {BLOGS} from '../urls/urls'
import Loading from './loading'
// import linkedin from '../linkedin.png'
export default class Blogs extends Component{
    state={
        blogs:[],
        loading:true
    }
    componentDidMount(){
        axios.get(BLOGS).then((response)=>{
            this.setState({
            blogs: response.data.reverse(),
            })
            this.setState({
                loading:false
            })
        }).catch(err=>{
            console.log("Unable to Get data"+err);
        })
    }
   
    createDate= (date)=>{
        return date.substring(8,10)+"/"+date.substring(5,7)+"/"+date.substring(0,4)
    }
    render(){
        if(!this.state.loading)
        {return(
            <div>

                <div className="blogs">
                <h1 className="blog-head">Day Blogs</h1>

                    {this.state.blogs.map((blog,i)=>(
                        <div key={i} className="blog" >
                            <div className="title">
                            <div className="date">{this.createDate(blog.createdAt)}</div>
                            <div>{blog.title}</div>
                            </div>
                            <hr/>
                            <p className="description">{blog.description}</p>
                        </div>
                    )
                    )}
                   
                </div>
            </div>
        )}else{
            return(
                <Loading/>
            )
        }
    }
}
// style={{background:(blog.url)?`url(${blog.url}) no-repeat center`:"#ffffff",backgroundSize:"100% 100%"}}