import React from 'react'
import axios from 'axios'
import { Link, Redirect } from 'react-router-dom';
import {LOGIN} from '../urls/urls'
export default class Login extends React.Component{
    state={
        email:"",
        password:"",
        redirect:false
    }
    handleSubmit=(e)=>{
        e.preventDefault();
        axios.post(LOGIN,{email:this.state.email,password:this.state.password}).then((response)=>{
            console.log(response.data.status);
            if(response.data.status==="success")
            {
                console.log("Successfully logged in!")
                console.log(response.data.token);
                localStorage.setItem('token',response.data.token);
                localStorage.setItem('userId',response.data.userId);
                localStorage.setItem('userName',response.data.userName); 
                this.props.changeLogin(response.data.token,response.data.userId);
                this.setState({
                    redirect:true
                })
            }
            else if(response.data.status==="incorrect"){
                console.log("Incorrect email or password")
            }
            else{
                console.log("Error :/");
            }
        })
    }

    render(){
        if(this.state.redirect===false){
        return(
        <div className="idk2"style={{backgroundColor:"#ffffff"}}>
            <div className="idk" style={{backgroundColor:"#ffffff"}}>
                <form onSubmit={this.handleSubmit}>
                    <label>Enter Email</label>
                    <input name="email" onChange={(e)=>(this.setState({
                        email:e.target.value
                    }))} value={this.state.email}type="email"/>
                    <label>Enter Password</label>
                    <input onChange={(e)=>(this.setState({
                        password:e.target.value
                    }))} name="password" value={this.state.password}type="password"/>
                    <button>Submit</button>
                </form>
                <div><Link to="/signup">Don't have account? Signup!</Link></div>
            </div>
        </div>
)  
    }else{
        return(
        <Redirect push to={"/viewblog/"+this.props.id}/>
        )}
}
}