import React from 'react'
import {BLOGSL} from '../urls/urls'
import axios from 'axios'
import {Link} from 'react-router-dom'
import parse from 'html-react-parser'
import Loading from './loading'
export default class Blogsl extends React.Component{
    state={
        blogs:[],
        loading:true
    }
    componentDidMount(){
        axios.get(BLOGSL).then((response)=>{
            this.setState({
            blogs: response.data.reverse(),
            loading:false
            })
        }).catch(err=>{
            console.log("Unable to Get data"+err);
        })
    }
    createHtml=(des)=>{
        var htmlObject = document.createElement('div');
        htmlObject.innerHTML = des;
        return document.createElement('div').innerHTML=des;
    }
    componentWillUnmount(){
        this._isMounted=false;
    }
    render(){
        if(!this.state.loading)
        {return(
            <div className="blogs">
                    <h1 className="blog-head">Blogs</h1>
                    {this.state.blogs.map((blog,i)=>(
                        <div key={i} className="blog">
                            <div className="title">
                            <div>{parse(blog.title)}</div>
                            {/* <h3>{this.createDate(blog.createdAt)}</h3> */}
                            </div>
                            <hr/>
                            <Link to={"/viewblog/"+blog._id} className="readfullblog" onClick={()=>(this.props.updateState(blog.title,blog.description,blog.comments,blog._id))}>Read Full Blog!</Link>
                            {/* <p className="description">{parse(blog.description)}</p> */}
                        </div>
                    )
                    )}  
                </div>
        )
        }
        else{
        return(
            <Loading/>
        )}
    }
}