import React from "react";
import pranshuphoto from "./0.jfif";
import { Link } from "react-router-dom";
import Projects from "./components/projects";
import Blogsl from "./components/blogsl";
import Github from "./github.png";
import Twitter from "./twittercircle.svg";
import Linkedin from "./linkedin.png";
class Introduction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      isDeleting: false,
      loopNum: 0,
      typingSpeed: 150,
    };
  }
  componentDidMount() {
    this.visible = true;
    this.handleType();
  }
  timer = null;
  handleType = () => {
    if (this.visible) {
      const { dataText } = this.props;
      const { isDeleting, loopNum, text, typingSpeed } = this.state;
      const i = loopNum % dataText.length;
      const fullText = dataText[i];
      this.setState({
        text: isDeleting
          ? fullText.substring(0, text.length - 1)
          : fullText.substring(0, text.length + 1),
        typingSpeed: isDeleting ? 30 : 150,
      });

      if (!isDeleting && text === fullText) {
        setTimeout(() => this.setState({ isDeleting: true }), 500);
      } else if (isDeleting && text === "") {
        this.setState({
          isDeleting: false,
          loopNum: loopNum + 1,
        });
      }
      this.timer = setTimeout(this.handleType, typingSpeed);
    }
  };
  componentWillUnmount() {
    clearTimeout();
    this.visible = false;
  }
  render() {
    let linkitem = [
      { name: "Projects", hlink: "/projects" },
      { name: "Blogs", hlink: "/blogs" },
      { name: "Day Blogs", hlink: "/dayblogs" },
      { name: "Contact", hlink: "/contact" },
    ];
    return (
      <div>
        <div id="introduction">
          <div>
            <div>Hello I am</div>
            <div
              onClick={() => {
                document.querySelector("body").classList.toggle("light");
              }}
              className="iam"
            >
              Pranshu Jain
            </div>
          </div>
          {/* <div>
            <span className="typing" >{ this.state.text }</span>
            <span id="cursor"/>
            </div> */}
          <div className="myintro">
            <img alt="pranshu jain" className="photostyle" src={pranshuphoto} />
            <div className="mydata">
              I am in my third year of B.tech CSE. I know Data Structures and
              Algorithms, Full Stack Web Development, App Developement and
              Blockchain.
            </div>
          </div>
          <div className="myint">
            <div className="head">Some Quick Links</div>
            <div className="info">
              <ul>
                {linkitem.map((item, i) => (
                  <li
                    onClick={() => {
                      this.setState((prev) => ({
                        check: !prev.check,
                      }));
                    }}
                    key={i}
                  >
                    <Link to={item.hlink}>{item.name}</Link>
                  </li>
                ))}
                <li
                  onClick={() => {
                    this.setState((prev) => ({
                      check: !prev.check,
                    }));
                    document.querySelector("body").classList.toggle("light");
                  }}
                >
                  <Link to="#">Change Theme</Link>
                </li>
              </ul>
              <ul>
                <li>
                  <a href="https://github.com/ipranshujain">
                    <img alt="Github Link" src={Github}></img>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/PranshuJain04">
                    <img width="32" alt="Twitter Link" src={Twitter}></img>
                  </a>
                </li>
                <li>
                  <a href=" https://www.linkedin.com/in/pranshu-jain-091448193/">
                    <img width="32" alt="Twitter Link" src={Linkedin}></img>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <div id="info">
            <div>I am Pranshu Jain and I welcome you to this site. Hope you will get the information you are looking to know about me. I am always ready to improve this site so if you find anything changable or material that will make the site more attractive contact me. Also If you find anything useful other than that you are always welcome for reviews</div>
          </div> */}
        <Projects />
        <Blogsl
          updateState={this.props.updateState}
          token={this.props.token}
          userId={this.props.userId}
        />
      </div>
    );
  }
}
export default Introduction;
