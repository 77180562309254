let on= "https://ipranshujain.herokuapp.com";
let off="http://localhost:5000";
let online = true;
let BLOGS = on+'/dayblogs/listblogs'
let PROJECTS =on+'/listprojects'
let CONTACT_URL=on+"/send"
let BLOGSL = on+'/blogs/listblogs'
let LOGIN= on+'/client/login'
let SIGNUP= on+"/client/signup"
let STATUS=on+"/client/getstatus/"
let COMMENT=on+"/blogs/addcomment/"
if(!online)
{
    BLOGS = off+'/dayblogs/listblogs'
    PROJECTS =off+'/listprojects'
    CONTACT_URL=off+"/send"
    BLOGSL = off+'/blogs/listblogs'
    LOGIN= off+"/client/login"
    SIGNUP= off+"/client/signup"
    STATUS=off+"/client/getstatus/"
    COMMENT=off+"/blogs/addcomment/"
}
export {BLOGS};
export {PROJECTS};
export {CONTACT_URL};
export {BLOGSL};
export {LOGIN};
export {SIGNUP};
export {STATUS};
export {COMMENT};