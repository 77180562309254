import React from 'react'
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import {COMMENT} from '../urls/urls'
// import {Link} from 'react-router-dom'
export default class AddComment extends React.Component{
    state={
        cdes:"",
    }
    handleSubmit=(event)=>
    {
        event.preventDefault();
        if(this.props.id===""||this.props.id===null){
            console.log("I feel an error");   
        }
        else{
          axios.post(COMMENT+this.props.id,
          {userName:localStorage.getItem("userName"),
          description:this.state.cdes,token:this.props.token});
        }
        this.props.changeRedirect(true);
        this.props.changeComment({comment:this.state.cdes,name:localStorage.getItem("userName")})
    }
    render(){
        if(this.props.redirect===false){
        return(
            <div style={{color:"#ffffff"}}>
            <div className="idk2 idk">      
            <form onSubmit={this.handleSubmit}>
            <label style={{fontSize:30}} htmlFor="description">Add Comment</label>
            <textarea onChange={(event)=>(this.setState({cdes:event.target.value}))} className="last-input"type="name" id="description" name="description">
            </textarea>
            <button >ADD COMMENT</button>
            </form>
            </div>
            </div>)       
        }
        else{
            return(
            <Redirect to={"/viewblog/"+this.props.id}/>
            );
        }
    }
}