import React from 'react';
import axios from 'axios'
import {SIGNUP} from '../urls/urls'
import { Redirect } from 'react-router-dom';
export default class Signup extends React.Component
{
    state={
        name:"",
        email:"",
        password:"",
        redirect:false,
    }
    handleSubmit=(e)=>{
        e.preventDefault();
        axios.post(SIGNUP,{name:this.state.name,email:this.state.email,password:this.state.password}).then(response=>{
            if(response.data.status==="success"){
                this.setState({
                    redirect:true
                })
            }
        }).catch(err=>{console.log(err)});
    }
    render(){
        if(this.state.redirect===false){
        return(
<div className="idk2">
    <h2>SIGN UP!</h2>
    <div className="idk">
       <form  action="/client/signup" method="POST" onSubmit={this.handleSubmit}>
       <label htmlFor="name">Full Name</label>
<input onChange={(e)=>this.setState({name:e.target.value})} type="name" required id="name" name="name"/>
       <label htmlFor="email">Email</label>
<input onChange={(e)=>this.setState({email:e.target.value})} type="email" required name="email" id="email"/>
       <label htmlFor="password">Password</label>
<input onChange={(e)=>this.setState({password:e.target.value})} type="password" id="password" name="password"/>
       <button>Sign Up!</button>
    </form>
    </div>
</div>
        )
    }
    else{
        return(
            <Redirect push to="/login"/>
        )
    }
}
}
