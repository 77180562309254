import React from 'react'
import plusicon from './plusicon.png'
import {Link} from 'react-router-dom'
import Github from './github.png'
import Twitter from './twittercircle.svg'
import Linkedin from './linkedin.png'
// import Theme from './theme.png'
class Navbar extends React.Component{
constructor(props){
        super(props);
        this.state={
            check:false
        }
    }
    render(){
        let arr=['P','R','A','N','S','H','U'];
        let linkitem=[{name:"Home",hlink:"/"},{name:"Projects",hlink:"/projects"},{name:"Blogs",hlink:"/blogs"},{name:"Day Blogs",hlink:"/dayblogs"},{name:"Contact",hlink:"/contact"}];
        let classToShow;
        let menuShow=""
        let icon;
        if(this.state.check){

           classToShow="display"
           menuShow="menu menudisplay"
           icon="plus animateIcon"            
        }
        else{
           classToShow="pranshu"
           menuShow="menu"
           icon="plus"
        }
        return (
        <div>
        <div className='navbar'>
            <div onClick={
                ()=>{
                    this.setState((prev)=>({
                        check:!prev.check
                    }))
                }
            } className={icon}>
                <img alt="navbar" src={plusicon}/>                
                {/* <svg width="40" height="40" fill="rgb(69, 117, 248)" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M11.5 0c6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5-11.5-5.153-11.5-11.5 5.153-11.5 11.5-11.5zm0 1c5.795 0 10.5 4.705 10.5 10.5s-4.705 10.5-10.5 10.5-10.5-4.705-10.5-10.5 4.705-10.5 10.5-10.5zm.5 10h6v1h-6v6h-1v-6h-6v-1h6v-6h1v6z"/></svg> */}
            </div>
            <div className={menuShow}>
             
                <ul>
                 {linkitem.map((item,i)=>(<li onClick={
                ()=>{
                    this.setState((prev)=>({
                        check:!prev.check
                    }))
                }
            } key={i}><Link to={item.hlink}>{item.name}</Link></li>))}
            <li   onClick={
                ()=>{

                    this.setState((prev)=>({
                        check:!prev.check
                    }))
                    document.querySelector("body").classList.toggle("light")
                }
            }><Link to="#" >Change Theme</Link></li>
                </ul>   
                <div className="profilelink">
                    <div>
                    <a href="https://github.com/ipranshujain"><img alt="Github Link" src={Github}></img></a>

                    </div>
                    <div>
                    <a href="https://twitter.com/PranshuJain04"><img width="32" alt="Twitter Link" src={Twitter}></img></a>
                    </div>
                    <div>
                    <a href=" https://www.linkedin.com/in/pranshu-jain-091448193/"><img width="32" alt="Twitter Link" src={Linkedin}></img></a>
                    </div>
                </div>
            </div>
        </div>
        <div  className={classToShow}>
        <div className="pranshujain">
          {  arr.map((a,i)=>(
              <div  onClick={()=>(console.log("This is working"))} key={i}>{a}</div>
          ))}
        </div>
        </div>
        </div>
        )
    }
}
export default Navbar;
// {(this.state.check===true)&&("display good")||("pranshu")}