import React from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom'
import {CONTACT_URL} from '../urls/urls'
class Contact extends React.Component{
  
  constructor(props) {
	super(props);
	this.state = {
  	name: '',
  	email: '',
    message: '',
    loading:false,
    dtext:"",
    error:false
	}
  }

  handleSubmit(e){
    this.setState((prev)=>({
        dtext:"Loading... Please Wait...",
        loading:true
    }))
    e.preventDefault();
    axios({
      method: "POST", 
      url:CONTACT_URL, 
      data:  this.state
    }).then((response)=>{
      if (response.data.status === 'success'){
        this.setState((prev)=>({
          dtext:"Messege Sent!, I will contact you soon : )"
        }))
        this.resetForm()
      }else if(response.data.status === 'fail'){
        this.setState((prev)=>({
          dtext:"Some error occurred while sending!",
          error:true
        }))
      }
    }).catch(err=>{
      console.log(err);
      this.setState((prev)=>({
        dtext:"Error Occured try again",
        error:true
      }))
    });

  }

  resetForm(){
    
     this.setState({name: '', email: '', message: ''})
  }
  
  render() {
	return(
    <div>
  	{(!this.state.loading)&&(
    <div className="contact-outer hidden">
  	<form className="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
  	<div className="form-group">
      	<label htmlFor="name">Name</label>
      	<input type="text" required className="form-control" id="name" value={this.state.name} onChange={this.onNameChange.bind(this)} />
  	</div>
  	<div className="form-group">
      	<label htmlFor="exampleInputEmail1">Email address</label>
      	<input type="email" required className="form-control" id="email" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
  	</div>
  	<div className="form-group">
      	<label htmlFor="message">Message</label>
      	<textarea required className="form-control" rows="5" id="message" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
  	</div>
  	<button type="submit" className="btn btn-primary">Submit</button>
    </form>
    </div>)}
    {(this.state.loading)&&(<div className="contact-outer"><div className="contact-form">{this.state.dtext}
    {this.state.error&&(<Link to="/contact" onClick={()=>(this.setState({
      loading:false,
      dtext:"",
      error:false
    }))}>Retry :/</Link>)}</div></div>)}
    
    </div>

	);
  }

  onNameChange(event) {
	this.setState({name: event.target.value})
  }

  onEmailChange(event) {
	this.setState({email: event.target.value})
  }

  onMessageChange(event) {
	this.setState({message: event.target.value})
  }
}

  
  export default Contact;