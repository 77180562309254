import React from "react";
import "./App.css";
import FullBlog from "./components/fullblog";
// import {Link} from 'react-router-dom'
import { Route } from "react-router-dom";
import Navbar from "./Navbar";
import Introduction from "./Introduction";
import Blogs from "./components/blogs";
import Projects from "./components/projects";
import Contact from "./components/contact";
import Blogsl from "./components/blogsl";
import Login from "./components/login";
import AddComment from "./components/addcomment";
import Signup from "./components/signup";

class App extends React.Component {
  state = {
    title: "Don't Giveup",
    description:
      "Don't Giveup the title of the blog says right, because you know that their is someone is the world who is more depressed in the world, life is single, time is limited, knowledge is unlimited but I can't absorb all of that in a moment. It sometimes take time to learn. Don't Compare yourself with others because Bill Gates say this. Think about death and start doing the efforts because you know no matter how you are going to die so why fear. Don't believe in the fact that science is devloping immortality, it may be true in the coming future but after we are in heaven seeing human efforts to earn immortality.",
    comments: [
      { name: "Sannu", comment: "ThankYou Very much" },
      { name: "Mannu", comment: "I saves my life!" },
    ],
    id: "",
    token: localStorage.getItem("token") || "1234",
    userId: localStorage.getItem("userId") || "",
    redirect: true,
  };
  updateState = (title, description, comments, id) => {
    this.setState({
      title,
      description,
      comments,
      id,
    });
  };
  updateId = (id) => {
    this.setState({
      id,
    });
  };
  changeLogin = (token, userId) => {
    this.setState({
      token: token,
      userId: userId,
    });
  };
  changeRedirect = (check) => {
    this.setState((prev) => ({ redirect: check }));
  };
  changeComment = (check) => {
    this.setState((prev) => ({ comments: prev.comments.concat(check) }));
  };
  render() {
    return (
      <div>
        <Navbar />
        <Route path="/" exact>
          <Introduction
            updateState={this.updateState}
            token={this.state.token}
            userId={this.state.userId}
            dataText={["Developer", "Designer", "Animator"]}
          />
        </Route>
        <Route path="/dayblogs" component={Blogs} />
        <Route path="/projects" component={Projects} />
        <Route path="/contact" component={Contact} />
        <Route path="/blogs">
          <Blogsl
            updateState={this.updateState}
            token={this.state.token}
            userId={this.state.userId}
          />
        </Route>
        <Route
          path={"/viewblog/:id"}
          render={(props) => (
            <FullBlog
              {...props}
              updateId={this.updateId}
              redirect={this.state.redirect}
              title={this.state.title}
              description={this.state.description}
              comments={this.state.comments}
              token={this.state.token}
              changeRedirect={this.changeRedirect}
              userId={this.state.userId}
            />
          )}
        />
        <Route path="/addcomment">
          <AddComment
            id={this.state.id}
            changeComment={this.changeComment}
            redirect={this.state.redirect}
            token={this.state.token}
            changeRedirect={this.changeRedirect}
            userId={this.state.userId}
          />
        </Route>
        <Route path="/login">
          <Login
            id={this.state.id}
            changeLogin={this.changeLogin}
            token={this.state.token}
            userId={this.state.userId}
          />
        </Route>
        <Route path="/signup">
          <Signup
            id={this.state.id}
            changeLogin={this.changeLogin}
            token={this.state.token}
            userId={this.state.userId}
          />
        </Route>
      </div>
    );
  }
}
export default App;
