import React from 'react'
import axios from 'axios';
import {Link} from 'react-router-dom'
import parse from "html-react-parser";
import {BLOGSL, STATUS} from '../urls/urls'
export default class FullBlog extends React.Component{
    state={
        status:"",
        update:false,
        data:{
            title:"",
            description:"",
            comments:[]
        }
    }
    componentDidMount(){
        // console.log("Kya majaz hai yeh :"+this.props.token);
        axios.get(STATUS+this.props.token).then(response=>{
        // console.log("Hello"+response.data.status);    
        if(response.data.status==="logged"){
                this.setState({
                    status:"/addcomment"
                })
            }
            else{
                this.setState({
                    status:"/login"
                })
            }
        })
        this.props.updateId(this.props.match.params.id);
        axios.get(BLOGSL).then(response=>{
            let data=response.data;
            for(let i=0;i<data.length;i++) {
            if(data[i]._id===this.props.match.params.id){
                this.setState({
                    data:data[i]
                });   
            }
        }
        }).catch(err=>console.log("Faltu joke nhi!"));
        this.props.changeRedirect(false);
    }
    addComment=()=>{

    }
    check=()=>{
        if(this.state.status==="login"){
            return "/login"
        }
            return "/addcomment"
    }
    render(){
        // console.log("Redirect is:"+this.props.redirect);
        // let title= this.props.title;
        // let description= this.props.description;
        // let comments = this.props.comments;
        // let id = this.props.id;
        return(
            <div className="fullblog">
                <div className="head">{this.state.data.title}</div>
                <hr/>
                <div className="story">{parse(this.state.data.description)}</div>
                <hr/>
                <div className="chead">Comments</div>
                <Link className="readfullblog" to={this.state.status}>Add Comment</Link>
                <ul className="comments">
                    {this.state.data.comments.map((comment,i)=>(
                    <div key={i}><li className="comment">{comment.name} : {comment.comment}</li>
                    </div>))}
                </ul>
            </div>)
    }
}
