import React,{Component} from 'react';
import axios from 'axios'
import {PROJECTS} from '../urls/urls'
import parse from "html-react-parser";
import Loading from './loading'
function Description({description}){
let x = description.split(":::");
if(x.length==1){
    return(
        <div className="description">{parse(description)}</div>
    )
}

else{
    return(
    <div className="description">{parse(x[1])}</div>)
}

}
export default class Projects extends Component{
    state={
        blogs:[],
        loading:true
    }
    
    componentDidMount(){
        axios.get(PROJECTS).then((response)=>{
            this.setState({
            blogs: response.data.reverse(),
            loading:false
            })
        }).catch(err=>{
            console.log("Unable to Get data"+err);
        })
    }
    createHtml=(des)=>{
        var htmlObject = document.createElement('div');
        htmlObject.innerHTML = des;
        return document.createElement('div').innerHTML=des;
    }
    componentWillUnmount(){
        this._isMounted=false;
    }
    render(){
        if(!this.state.loading)
        return(
            <div className="blogs">
                    <h1 className="blog-head">Projects</h1>
                    {this.state.blogs.map((blog,i)=>(
                        <div key={i} className="blog">
                            <div className="title">
                                <div>{parse(blog.title)}</div>
                            {/* <h3>{this.createDate(blog.createdAt)}</h3> */}
                            </div>
                            <hr/>
                           <Description description={blog.description}/>

                        </div>
                    )
                    )}
                   
                </div>
        )
        else
        return(
            <Loading/>
        )
    }
}