import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom'
import HttpsRedirect from 'react-https-redirect'
ReactDOM.render(
  <HttpsRedirect>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter> 
    </React.StrictMode>
  </HttpsRedirect>
,
  document.getElementById('root')
);
serviceWorker.register();
